import React, { useContext, useEffect  } from 'react';
import { Container , Row , Col , Button} from 'react-bootstrap';
import "./GenerateReport.scss"
import ScreenScore from '../../context/ScreensContext/ScreenScore';
import {baseUrl , redirectUrl} from '../../config';
import axios from "axios";
import OperationOrItContext from '../../context/OperationOrIt/OperationOrItContext';

const GenerateReport = () => {
    const [score,SetScreenScore] = useContext(ScreenScore);
    //change top steps
    useEffect(()=>{
        SetScreenScore({...score,Step:6})
    },[])

    // localStorage.setItem('scoreData', JSON.stringify(score));

    const [userType] = useContext(OperationOrItContext)


    const handleGeneratePdfFile = () => {

            const finalDataObject = {
                'companySize':score.companysize,
                "industry":score.Industry,
                "jobTitle":score.jobTitle,
                "firstName":score.ProfileData.firstName,
                "secondName": score.ProfileData.secondName,
                "companyName":score.ProfileData.companyName,
                "email":score.ProfileData.email,
                "phoneNumber": score.ProfileData.phoneNumber,
                "country": score.ProfileData.country,
                "city": score.ProfileData.city,
                "QuestionOneScore": score.QuestionOne,
                "QuestionTwoScore": score.QuestionTwo,
                "QuestionThreeScore": score.QuestionThree,
                "QuestionThreeItOneScore": score.QuestionThreeItOne,
                "QuestionThreeItTwoScore": score.QuestionThreeItTwo,
                "QuestionFourScore": score.QuestionFour,
                "QuestionFiveScore": score.QuestionFive,
                "jobType":userType

            }

        axios({
                method: "post",
                url: `${baseUrl}/register`,
                data:finalDataObject,
                headers: { "Content-Type": "application/json" },
            }).then((res) => {
                window.location.replace(`${redirectUrl}?registerId=${res?.data}`)
        })

    }

    return ( 
        <>
            <Container>
                <section className='start-parent mt-5 end-step'>
                    <Row className='align-items-center mt-5 my-md-5 flex-column flex-md-row'>
                        <Col md={12}>
                            <div className='cont-heading text-center'>
                                Thanks for answering those questions
                            </div>
                            <div className="button-parent d-flex justify-content-center">  
                                <Button className="btn big-yellow-btn px-5" onClick={handleGeneratePdfFile}>Generate Report</Button>
                            </div>
                        </Col>
                    </Row>
                </section>
            </Container>            
        </>
    );
}

export default GenerateReport;





