/* eslint-disable eqeqeq */
import React, { useContext, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import LeftImageBlock from "../../LeftImageBlock/LeftImageBlock";
import image from "../../../assets/img/stepTwoImage.svg";
import NextPrevois from "../../Blocks/NextPrevois/NextPrevois";
import "./QuestionTwo.scss";
import ScreenScore from "../../../context/ScreensContext/ScreenScore";
import OperationOrItContext from "../../../context/OperationOrIt/OperationOrItContext";

const QuestionTwo = () => {

  const [score,SetScreenScore] = useContext(ScreenScore);
  const [StepInsideScore, setStepInsideScore] = useState({default:0});
/***user type* */
const [userType] = useContext(OperationOrItContext)


  /*
  path this handle change as props ro set the step value 
  
  */ 
  const handleQuestionScore = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setStepInsideScore({ ...StepInsideScore, [name]: parseInt(value) });
    SetScreenScore({...score,QuestionTwo:parseInt(value)})
  };

    // calculate totla score
    const handleTotalState = ()=> {
      let  sumValues =  Object.values(StepInsideScore).reduce((a, b) => a + b);
      SetScreenScore({...score,QuestionTwo:sumValues,progressOnPages:2,refreshFlag:1})
    }


//change top steps
useEffect(()=>{
  SetScreenScore({...score,Step:2})
},[])

  return (
    <Container>
      {console.log("st twoooooo",Object.keys(StepInsideScore).length)}
      <LeftImageBlock
        image={image}
        title1={`Business Priorities`}
        text1={`These questions are related your organization's level of achievement across various organization priorities.`}
        text2={`Organizational priorities are outcomes that an organization considers important and sets out to achieve. It is essential for organizations to prioritize outcomes and measure the level of achievement for each.`}
        title2={`Does your organization have sustainability targets and measure KPIs?`}
        text3={``}
      />
      <div className="d-flex justify-content-between flex-column flex-md-row align-items-center radio answers-parent">
        <label>
          <input type="radio" name="questionTwo" value="5" onChange={handleQuestionScore} defaultChecked={score?.QuestionTwo == 5 ? true : false}/>
          <span type="button" className="btn btn-primary">
            Yes
          </span>
        </label>

        <label>
          <input type="radio" name="questionTwo" value="3" onChange={handleQuestionScore} defaultChecked={score?.QuestionTwo == 3 ? true : false}/>
          <span type="button" className="btn btn-primary px-5">
            Not currently, but in the process
          </span>
        </label>

        <label>
          <input type="radio" name="questionTwo" value="0" onChange={handleQuestionScore} defaultChecked={score?.QuestionTwo == 0 ? true : false}/> 
          <span type="button" className="btn btn-primary ">
            No
          </span>
        </label>
      </div>


        {
          userType == "Operation" ? 
            <NextPrevois 
                next={Object.keys(StepInsideScore).length == 2 || score.QuestionTwo >= 0 ? `/question-3-operation` : false}
                prev={`/question-1`}
                handleTotalState={handleTotalState} 
            /> 
          :
            <NextPrevois 
                next={Object.keys(StepInsideScore).length == 2 || score.QuestionTwo >= 0 ? `/question-3-it` : false} 
                prev={`/question-1`}
                handleTotalState={handleTotalState}  
            /> 
        }
    </Container>
  );
};

export default QuestionTwo;
