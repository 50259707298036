import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faYoutube,
  faTwitter,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";

const Landing = () => {
  return (
    <>
      <section className="landing-parent w-100 my-5 ">
        <div className="cont-text container">
          <p>
            <span className="yellow-text">
              Organizational resiliency reflects{" "}
            </span>{" "}
            an enterprise's ability to respond to unexpected events and the
            speed at which it can return to normal performance levels following
            a major disruption. Over the past couple of years, companies around
            the world have focused on adapting to business disruptions,
            leveraging digital capabilities to accelerate business operations,
            and automating processes to take advantage of the changing market
            conditions, quickly and efficiently.
          </p>

          <p>
            Resilient organizations are more robust, competitive, and able to
            continue doing business despite tumultuous global conditions. IDC’s
            Resiliency Assessment tool will help you determine your how
            resilient your organization is today, and what you need to do to
            become more resilient tomorrow.
          </p>

          <p>
            Once you have completed this assessment, you will get a full
            overview of your organizational resiliency with actionable
            recommendations. These will enable you to improve your business, in
            ways that help ensure that you are prepared for the future.
          </p>

          <p className="yellow-text bold-text">
            This assessment will take no more than 10 minutes to complete.
          </p>
        </div>
        <div className="container-fluid">
        <div className="button-parent d-flex justify-content-center">
          <Link to="/start" className="btn big-yellow-btn px-5">
            Start the IDC Resiliency Assessment
          </Link>
        </div>
        <div className="social-links-parent d-flex align-items-center">
          <p>Follow us on:</p>
          <div className="icons-parent">
            <a href="/" className="follow-icon">
              <FontAwesomeIcon icon={faFacebookF} />
            </a>
            <a href="/" className="follow-icon">
              <FontAwesomeIcon icon={faTwitter} />
            </a>
            <a href="/" className="follow-icon">
              <FontAwesomeIcon icon={faLinkedinIn} />
            </a>
            <a href="/" className="follow-icon">
              <FontAwesomeIcon icon={faYoutube} />
            </a>
            <a href="/" className="follow-icon">
              <FontAwesomeIcon icon={faInstagram} />
            </a>
          </div>
        </div>
      </div>
      </section>
 
    </>
  );
};

export default Landing;
