import React ,{useRef , useEffect , useState}  from 'react';
import Table from 'react-bootstrap/Table';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport } from '@fortawesome/free-solid-svg-icons';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { baseUrl } from '../../../config';
import axios from 'axios';
import { useNavigate } from "react-router-dom";

import './Dashboard.scss';
const Dashboard = () => {
    const navigate = useNavigate();
    const tableRef = useRef(null);
    const [usersData,SetUseresData] =useState([]);

    useEffect(() => {
        if(localStorage.getItem('AdminToken') && localStorage.getItem('AdminToken').length > 0) {

            const headers = {'SAPAuthHEADER':`${localStorage.getItem('AdminToken')}`}
            axios.get(`${baseUrl}/GetAllUsers`,{ headers }).then(res => {
                        SetUseresData(res.data);
                        console.log(res.data);
                    }).catch(err => {
                        console.table(err);
                        console.log(err?.response.status)
                        if(err?.response.status == 401) {
                            console.log('unauthoeriezed!');
                            localStorage.removeItem("AdminToken");
                            navigate("/")
                        }
                    })
        
        }else {
            navigate("/login");
        }
    }, []);


    const handleLogOut = ()=> {
        if(localStorage.getItem('AdminToken')) {
            localStorage.removeItem("AdminToken");
            navigate("/login")
        } else {
            navigate("/login")
        }
    }


    return ( 
        <>
            <div className='w-80 px-3' >
                <div className='relative-parent'>
                    <div className='export-parent text-end mb-3'>
                        <DownloadTableExcel filename="users table" sheet="users" currentTableRef={tableRef.current}>
                            <button className='btn btn-success btn-lg mx-3'>
                                <FontAwesomeIcon icon={faFileExport} className="mx-2"/>
                                Export
                            </button>
                        </DownloadTableExcel>
                        
                        <button className='btn btn-secondary btn-lg' onClick={handleLogOut}>
                            Logout
                        </button>
                    </div>
                </div>
                    <Table striped bordered hover variant="dark" responsive ref={tableRef}>
                        
                        <tbody>
                            <tr>
                                <th>#</th>
                                <th>Full Name</th>
                                <th>country</th>
                                <th>city</th>
                                <th>Industry</th>
                                <th>Email</th>
                                <th>Phone Number</th>
                            </tr>

                            {
                                usersData.length > 0 ? 
                                usersData.map((user , index) => {
                                    return(
                                        <tr key={index}>
                                            <td>{index}</td>
                                            <td>{user?.firstName + ' ' + user?.secondName} </td>
                                            <td>{user?.country}</td>
                                            <td>{user?.city}</td>
                                            <td>{user?.industry}</td>
                                            <td>{user?.email}</td>
                                            <td>{user?.phoneNumber}</td>
                                        </tr>
                                    )
                                }) : 
                                <tr>
                                    <td className='none'></td>
                                    <td className='none'></td>
                                    <td className='none'></td>
                                    <td className='none'></td>
                                    <td className='none'></td>
                                    <td className='none'></td>
                                    <td colSpan={10} className="text-center"> no data to show here</td>
                                </tr>
                            }

                        </tbody>
                    </Table>
            </div>
                    
        </>
    );
}

export default Dashboard;