import React, { useContext , useState , useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import NextPrevois from "../Blocks/NextPrevois/NextPrevois";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import OperationOrItContext from "../../context/OperationOrIt/OperationOrItContext";
import ScreenScore from "../../context/ScreensContext/ScreenScore";

const StartPage = () => {
  const [userType, setUserType] = useContext(OperationOrItContext);
  const [score,SetScreenScore] = useContext(ScreenScore); 


  useEffect(()=>{
    SetScreenScore({ ...score, companysize: 'less-than-100',Industry:"Agriculture",jobTitle:"Chief Information Officer"});
  },[])


  const [otherIndustry,setOtherIndustry] = useState(false);
  const [otherItTitle,setOtherItTitle] = useState(false);
  const [otherOperationTitle,setOtherOperationTitle] = useState(false); 

  const handleUserType = (e) => {
    let val = e.target.value;
    let user = e.target.selectedOptions[0].getAttribute('data-value-type');
    setUserType(user);
    if (val == "Other IT title") {
      setOtherItTitle(true)
      setOtherOperationTitle(false)
      
    } else if((val == "Other Operations title")) {
      setOtherItTitle(false)
      setOtherOperationTitle(true)
    }  else {
      setOtherItTitle(false)
      setOtherOperationTitle(false)
    }
    SetScreenScore({...score,jobTitle:val});
  };


    // calculate totla score
    const handleSaveData = (e)=> {
      let name = e.target.name;
      let value = e.target.value;

      if(value === "Other" && name === "Industry") {  
        setOtherIndustry(true)
      } else {
        setOtherIndustry(false)
      }
      if(value === "Other" && name === "companysize") { 
        setOtherItTitle(true)
      } else {
        setOtherItTitle(false)
      }
      SetScreenScore({...score,[name]: value});
    }

    const handlechangeTextOther = (e) => {
      let name = e.target.name;
      let value = e.target.value;
      SetScreenScore({...score,[name]: value});
    }


  return (
    <>
      {console.log(userType)}
      <Container>
        <section className="start-parent mt-5 ">
          <Row>
            <Col>
              <div className="cont-heading">
                <h1 className="bold-text">Profile</h1>
              </div>
              <div className="questions-parent">
                <div className="cont-text d-flex justify-content-between flex-column flex-md-row mb-5">
                  <p className="fs-4">
                    These questions are aimed at identifying:
                  </p>
                  <p className="blod-text fs-4">
                    Please complete the below profiling details.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="select-parent d-flex justify-content-between flex-column flex-md-row">
                <p className="orange-text">Your organization's size</p>
                <div className="cont-select d-flex flex-column ">
                  <label htmlFor="companySize">Company Size:</label>
                  <div className="relative-parent bg-white">
                    <select className="white-select bg-transparent orange-cl" name="companysize" onChange={handleSaveData}>
                      <option value="less-than-100">
                        Less than 100 employees
                      </option>
                      <option value="more-than-100">
                        More than 100 employees
                      </option>
                    </select>
                    <div className="abosulte-icon orange-cl">
                      <FontAwesomeIcon icon={faArrowDown} />
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="select-parent d-flex justify-content-between flex-column flex-md-row">
                <p className="blue-text">
                  The industry in which your organization operates
                </p>
                <div className="cont-select d-flex flex-column">
                  <label htmlFor="companySize">Industry</label>
                  <div className="relative-parent white-bg">
                    <select className="white-select blue-cl"  name="Industry" onChange={handleSaveData}>
                      {/* <option value="IT" >IT</option>
                      <option value="Operation" >Operation</option> */}
                      <option value="Agriculture">Agriculture</option>
                      <option value="Banking">Banking</option>
                      <option value="Communications">Communications</option>
                      <option value="Construction">Construction</option>
                      <option value="Distribution, storage and logistics">
                        Distribution, storage and logistics
                      </option>
                      <option value="Education">Education</option>
                      <option value="Financial services">
                        Financial services
                      </option>
                      <option value="Government">Government</option>
                      <option value="Healthcare">Healthcare</option>
                      <option value="Hospitality and Accommodation">
                        Hospitality and Accommodation
                      </option>
                      <option value="Insurance">Insurance</option>
                      <option value="Leisure and Entertainment">
                        Leisure and Entertainment
                      </option>
                      <option value="Manufacturing">Manufacturing</option>
                      <option value="Media">Media</option>
                      <option value="Minerals, Mining and Quarrying">
                        Minerals, Mining and Quarrying
                      </option>
                      <option value="Oil and Gas">Oil and Gas</option>
                      <option value="Professional services">
                        Professional services{" "}
                      </option>
                      <option value="Trade - Retail">Trade - Retail </option>
                      <option value="Trade - Wholesale">
                        Trade - Wholesale
                      </option>
                      <option value="Transportation">Transportation</option>
                      <option value="Utilities and Energy">
                        Utilities and Energy
                      </option>
                      <option value="Other">
                        Other (please specify)
                      </option>
                    </select>
                    <div className="abosulte-icon blue-cl">
                      <FontAwesomeIcon icon={faArrowDown} />
                    </div>
                  </div>
                </div>
                {console.log(otherIndustry)}
              </div>
              {
                otherIndustry && 
                <div className='select-parent d-flex justify-content-end no-after'>
                    <div className='cont-select d-flex flex-column'>
                        <div className='relative-parent'>
                            <input type="text" placeholder="Other Industry" onChange={handlechangeTextOther} name="Industry" className='colored-input blue-cl'/>
                        </div>
                    </div>
                </div>
              }
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="select-parent d-flex justify-content-between flex-column flex-md-row">
                <p className="green-text">
                  The line of business to which your role belongs
                </p>
                <div className="cont-select d-flex flex-column">
                  <label htmlFor="companySize">Current job title:</label>
                  <div className="relative-parent white-bg">
                    <select
                      className="white-select green-cl"
                      onChange={handleUserType}
                    >
                      <option value="Chief Information Officer" data-value-type="IT">
                        Chief Information Officer (CIO)
                      </option>
                      <option value="Vice/Deputy Head of IT" data-value-type="IT">Vice/Deputy Head of IT</option>
                      <option value="IT head" data-value-type="IT">IT head</option>
                      <option value="IT director" data-value-type="IT" >IT director</option>
                      <option value="Senior IT manager" data-value-type="IT">Senior IT manager</option>
                      <option value="Other IT title" data-value-type="IT">
                        Other IT title (please specify)
                      </option>
                      <option value="Chief Operation Officer" data-value-type="Operation">
                        Chief Operation Officer (COO)
                      </option>
                      <option value="Vice/Deputy Head of Operations" data-value-type="Operation">
                        Vice/Deputy Head of Operations
                      </option>
                      <option value="Operations head" data-value-type="Operation">Operations head</option>
                      <option value="Operations director" data-value-type="Operation">Operations director</option>
                      <option value="Senior operations manager" data-value-type="Operation">
                        Senior operations manager
                      </option>
                      <option value="Other Operations title" data-value-type="Operation">
                        Other Operations title (please specify) 
                      </option>
                    </select>
                    <div className="abosulte-icon green-cl">
                      <FontAwesomeIcon icon={faArrowDown} />
                    </div>
                  </div>
                </div>
              </div>

              {
                otherItTitle && 
                  <div className='select-parent d-flex justify-content-end no-after'>
                      <div className='cont-select d-flex flex-column'>
                          <div className='relative-parent'>
                              <input type="text" placeholder="Other It Title" onChange={handlechangeTextOther} name="jobTitle" className='colored-input green-cl'/>
                          </div>
                      </div>
                  </div>
              }

              {
                otherOperationTitle && 
                  <div className='select-parent d-flex justify-content-end no-after'>
                      <div className='cont-select d-flex flex-column'>
                          <div className='relative-parent'>
                              <input type="text" placeholder="Other Operation Title" onChange={handlechangeTextOther} name="jobTitle" className='colored-input green-cl'/>
                          </div>
                      </div>
                  </div>
              }
            </Col>
          </Row>
        </section>
      </Container>
      <NextPrevois next={`/question-1`} prev={`/`} />
    </>
  );
};

export default StartPage;
