import React, { useContext, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import LeftImageBlock from "../../LeftImageBlock/LeftImageBlock";
import "./Operation1.scss";
import image from "../../../assets/img/Operational.svg";
import MatchedRadio from "../../Blocks/MatchedRadio/MatchedRadio";
import ScreenScore from "../../../context/ScreensContext/ScreenScore";
import NextPrevois from "../../Blocks/NextPrevois/NextPrevois";
const Operation1 = () => {
  const [score, SetScreenScore] = useContext(ScreenScore);
  const [StepInsideScore, setStepInsideScore] = useState({default:0});
//change top steps
useEffect(()=>{
  SetScreenScore({...score,Step:3})
},[])
  /*
  path this handle change as props ro set the step value 
  
  */
  const handleQuestionScore = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setStepInsideScore({ ...StepInsideScore, [name]: parseInt(value) });
  };

  // calculate totla score
  const handleTotalState = () => {
    let sumValues = Object.values(StepInsideScore).reduce((a, b) => a + b);
    SetScreenScore({ ...score, QuestionThree: sumValues ,Operation1Data:StepInsideScore,progressOnPages:3,refreshFlag:1}); 

    // localStorage.setItem('scoreData', JSON.stringify(score)); 
  };

  return (
    <Container>
      <LeftImageBlock
        image={image}
        title1={`Operational priorities
        `}
        text1={`These questions are related your organization's level of achievement across various organization priorities.
        `}
        text2={`Organizational priorities are outcomes that an organization considers important and sets out to achieve. It is essential for organizations to prioritize outcomes and measure the level of achievement for each.`}
        title2={`For each of the following operational priorities how would you rate the level of achievement your organization has achieved in the past 1-2 years? `}
        text3={`Please rate each using a 5-scale where 1 means "very low achievement" and 5 means "very high achievement".`}
      />
      <MatchedRadio
        title="Accelerate time to market for new products and services"
        name="Accelerate"
        values={[0, 2, 3, 4, 5]}
        handleQuestionScore={handleQuestionScore}
        savedValue={score.Operation1Data?.Accelerate}

      />
      <MatchedRadio
        title="Improve employee productivity"
        name="productivity"
        values={[0, 2, 3, 4, 5]}
        handleQuestionScore={handleQuestionScore}
        savedValue={score.Operation1Data?.productivity} 

      />
      <MatchedRadio
        title="Improve operational efficiency"
        name="efficiency"
        values={[0, 1, 2, 3, 5]}
        handleQuestionScore={handleQuestionScore}
        savedValue={score.Operation1Data?.efficiency}
        
      />
      <MatchedRadio
        title="Improve employee retention"
        name="retention"
        values={[0, 1, 2, 3, 4]}
        handleQuestionScore={handleQuestionScore}
        savedValue={score.Operation1Data?.retention}

      />
      <MatchedRadio
        title="Drive environmental sustainability initiatives "
        name="sustainability"
        values={[0, 2, 3, 5, 8]}
        handleQuestionScore={handleQuestionScore}
        savedValue={score.Operation1Data?.sustainability}

      />
      <MatchedRadio
        title="Ensure regulatory compliance around environmental sustainability "
        name="compliance"
        values={[0, 2, 3, 5, 8]}
        handleQuestionScore={handleQuestionScore}
        savedValue={score.Operation1Data?.compliance}
      />

      <NextPrevois
        prev={`/question-2`}
        next={Object.keys(StepInsideScore).length == 7 ||  Object.keys(score.Operation1Data).length ==  7  ? `/question-4` : false} 
        handleTotalState={handleTotalState}
        
      />
    </Container>
  );
};

export default Operation1;
