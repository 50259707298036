import React, { useContext, useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import LeftImageBlock from "../LeftImageBlock/LeftImageBlock";
import image from "../../assets/img/TechnologySpending.svg";
import NextPrevois from "../Blocks/NextPrevois/NextPrevois";
import "./TechStepThree.scss";
import ScreenScore from "../../context/ScreensContext/ScreenScore";

const TechStepThree = () => {
  const [score, SetScreenScore] = useContext(ScreenScore);
  const [StepInsideScore, setStepInsideScore] = useState({default:0});

  /*
    path this handle change as props ro set the step value 
    
    */
  const handleQuestionScore = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (value == 0) {
      setStepInsideScore({ ...StepInsideScore, [name]: parseInt(value) });
    } else if (value >= 1 && value <= 4) {
      if (name == "2021") {
        setStepInsideScore({ ...StepInsideScore, [name]: 3 });
      } else {
        setStepInsideScore({ ...StepInsideScore, [name]: 4 });
      }
    } else if (value >= 5 && value <= 9) {
      if (name == "2021") {
        setStepInsideScore({ ...StepInsideScore, [name]: 4 });
      } else {
        setStepInsideScore({ ...StepInsideScore, [name]: 7 });
      }
    } else if (value >= 10) {
      if (name == "2021") {
        setStepInsideScore({ ...StepInsideScore, [name]: 5 });
      } else {
        setStepInsideScore({ ...StepInsideScore, [name]: 10 });
      }
    }
  };

  // calculate totla score
  const handleTotalState = () => {
    let sumValues = Object.values(StepInsideScore).reduce((a, b) => a + b);
    SetScreenScore({ ...score, QuestionThreeItOne: sumValues ,progressOnPages:3,refreshFlag:1});
    // localStorage.setItem('scoreData', JSON.stringify(score)); 
  };

//change top steps
useEffect(()=>{
  SetScreenScore({...score,Step:3})
},[])
  return (
    <Container>
      {console.log("score", score)}
      {console.log("StepInsideScore", StepInsideScore)}
      <LeftImageBlock
        image={image}
        title1={`Technology Spending`}
        text1={`These questions are related your organization's level of achievement across various organization priorities.`}
        text2={`Organizational priorities are outcomes that an organization considers important and sets out to achieve. It is essential for organizations to prioritize outcomes and measure the level of achievement for each.`}
        title2={`Based on your best estimate, what percentage of your organization's spending on technology in 2021 went on projects to address environmental sustainability, employee wellbeing, carbon neutrality, and process transformation to build a sustainable and resilient business, and what percentage do you expect it to be in 2022 and 2023?`}
        text3={`Please enter a percentage for each`}
      />
      <div className="answers-parent">
        <Row className="mb-5 step-three-tech">
          <Col md={6} xs={8}>
            <span className="bold-text question-text">Spent in 2021</span>
          </Col>
          <Col md={6} xs={4}>
            <div className="d-flex justify-content-end">
              <div className="relative-parent">
                <input
                  className="white-input"
                  type="number"
                  name="2021"
                  onChange={handleQuestionScore}
                  max="100"
                  min="0"
                />
                <span className="yellow-text bold-text absolute-percentage">
                  %
                </span>
              </div>

              {/* <div className="checkbox-parent-white d-flex align-items-center">
                                <input type="checkbox"/>
                                <span className="yellow-text bold-text">Don't know </span>
                            </div> */}
            </div>
          </Col>
        </Row>

        <Row className="mb-5 step-three-tech">
          <Col md={6} xs={8}>
            <span className="bold-text question-text">
              Expected spend for 2022
            </span>
          </Col>
          <Col md={6} xs={4}>
            <div className="d-flex justify-content-end">
              <div className="relative-parent">
                <input
                  className="white-input"
                  name="2022"
                  type="number"
                  onChange={handleQuestionScore}
                  max="100"
                  min="0"
                />
                <span className="yellow-text bold-text absolute-percentage">
                  %
                </span>
              </div>

              {/* <div className="checkbox-parent-white d-flex align-items-center">
                                <input type="checkbox"/>
                                <span className="yellow-text bold-text">Don't know </span>
                            </div> */}
            </div>
          </Col>
        </Row>

        <Row className="step-three-tech">
          <Col md={6} xs={8}>
            <span className="bold-text question-text">
              Expected spend for 2023
            </span>
          </Col>
          <Col md={6} xs={4}>
            <div className="d-flex justify-content-end">
              <div className="relative-parent">
                <input
                  className="white-input"
                  type="number"
                  name="2023"
                  onChange={handleQuestionScore}
                  max="100"
                  min="0"
                />
                <span className="yellow-text bold-text absolute-percentage">
                  %
                </span>
              </div>

              {/* <div className="checkbox-parent-white d-flex align-items-center">
                                <input type="checkbox"/>
                                <span className="yellow-text bold-text">Don't know </span>
                            </div> */}
            </div>
          </Col>
        </Row>
      </div>
      <NextPrevois
        next={Object.keys(StepInsideScore).length == 4 ? `/question-3-it-two` : false}
      
        prev={`/question-2`} handleTotalState={handleTotalState}/>
    </Container>
  );
};

export default TechStepThree;
