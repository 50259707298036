import React , {useState} from 'react';

import { Container , Row , Col  } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import { baseUrl } from '../../../config';
import { useNavigate } from "react-router-dom";
import './Login.scss'





const Login = () => {
    const [userName,setUserName] = useState('');
    const [UserPassword,setUserPassword] = useState('');

    const [worngLogin , setWrongLogin] = useState(false);


    const navigate = useNavigate();

    const handleChange = (e) => {
        if(e.target.name === "userName") {
            setUserName(e.target.value);
        } else if ( e.target.name === "password") {
            setUserPassword(e.target.value)
        }
    } 

    const handleLogin = (e) => {
        e.preventDefault();
        const userObject = {
            'username':userName,
            'password':UserPassword,
        }

        axios({
            method: "post",
            url: `${baseUrl}/Login`,
            data:JSON.stringify(userObject),
            headers: { "Content-Type": "application/json" },
        }).then((res) => {
            localStorage.setItem("AdminToken", res.data);
            navigate("/dashboard")
        }).catch(err => {
            setWrongLogin(true);
            console.log(err , 'error message')
        })
    }

    return ( 
        <>
            <section className='login-screen-parent mt-5'>
                <Container>
                    <Row className='align-items-center justify-content-center'>
                        <Col md={6} >
                            <div className='login-form-parent'>
                                <Form onSubmit={handleLogin}>
                                    <Form.Group className="mb-4" controlId="formBasicUserNAme">
                                        
                                        <Form.Control className='white-input w-100' type="text" placeholder="Enter UserName" onChange={handleChange} name='userName'/>
                                    </Form.Group>

                                    <Form.Group className="mb-4" controlId="formBasicPassword">
                                        
                                        <Form.Control className='white-input w-100' type="password" placeholder="Password" onChange={handleChange} name='password'/>
                                    </Form.Group>

                                    {
                                        worngLogin && 
                                        <div className='login-error-message'>
                                            username or password are invalid , please try again
                                        </div>
                                    }

                                    <div className='button-parent my-3'>
                                        <button className='btn big-yellow-btn px-5' type="submit">
                                            Login
                                        </button>
                                    </div>

                                </Form>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}

export default Login;