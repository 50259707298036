import React, { useContext, useEffect , useState } from 'react';
import { Container , Row , Col , Button} from 'react-bootstrap';
import NextPrevois from '../Blocks/NextPrevois/NextPrevois';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import finalImage from '../../assets/img/FinalPageImage.svg';
import "./FinalPage.scss"
import ScreenScore from '../../context/ScreensContext/ScreenScore';
const FinalPage = () => {
    const [score,SetScreenScore] = useContext(ScreenScore);
    const [anotherCountry, setAnotherCountry] = useState(false);
    const [profileInfo,setProfileInfo] = useState({});
    //change top steps
    useEffect(()=>{
        SetScreenScore({...score,Step:5})
    },[])
    

    const handleChnageCountry = (e) => {
        let val = e.target.value;
        setProfileInfo({ ...profileInfo, country: val });
        if(val === "Other") {
            setAnotherCountry(true)
        } else {
            setAnotherCountry(false)
        }
    }


    const handlechangeProfileData = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        setProfileInfo({ ...profileInfo, [name]: value });
        Object.keys(profileInfo).forEach(key => {
            if (profileInfo[key] === '') {
                delete profileInfo[key];
            }
        });
    };


    // abend these data to the score object 
    const handleTotalState = ()=> {
        SetScreenScore({...score,ProfileData:profileInfo,progressOnPages:8,refreshFlag:1})
        // localStorage.setItem('scoreData', JSON.stringify(score)); 
    }



    return ( 
        <>
            <Container>
                <section className='start-parent mt-5 end-step'>
                    <Row className='align-items-center my-md-5 flex-column flex-md-row'>
                        <Col md={4}>
                            <div className="cont-img text-center"> 
                                <img src={finalImage} alt="profile" />
                            </div>
                            
                        </Col>
                        <Col md={8}>
                            <div className="cont-heading"> 
                                <h1 className='bold-text'>Profile</h1> 
                                <p className='fs-4'>These questions are aimed at identifying:</p>
                            </div>
                            
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p className='text-md-end'>Please complete the below profiling details.</p>
                        </Col>
                    </Row>
                    <Row>
                        
                        <Col>
                            <div className='select-parent d-flex justify-content-between flex-column flex-md-row'>
                                <p className='orange-text'>Knowing you better</p>
                                <div className='cont-select d-flex flex-column '>
                                    <label htmlFor='companySize'>Contact Information</label>
                                    <div className='relative-parent'>
                                        <input type="text" placeholder="First Name" onChange={handlechangeProfileData} name="firstName" className='colored-input orange-cl'/>
                                    </div>
                                </div>
                            </div>

                            <div className='select-parent d-flex justify-content-end no-after'>
                                <div className='cont-select d-flex flex-column'>
                                    
                                    <div className='relative-parent'>
                                        <input type="text" placeholder="Second Name" onChange={handlechangeProfileData} name="secondName" className='colored-input orange-cl'/>
                                    </div>
                                </div>
                            </div>

                            <div className='select-parent d-flex justify-content-end no-after'>
                                <div className='cont-select d-flex flex-column'>
                                    <div className='relative-parent'>
                                        <input type="text" placeholder="Company Name" onChange={handlechangeProfileData} name="companyName" className='colored-input orange-cl'/>
                                    </div>
                                </div>
                            </div>

                            <div className='select-parent d-flex justify-content-end no-after has-disclamer'>
                                <div className='cont-select d-flex flex-column'>
                                    <div className='relative-parent d-flex justify-content-end align-md-end flex-column flex-md-row'>
                                        <p className='disclaimer-text'>Customized report will be sent to this email</p>
                                        <input type="email" placeholder="Busniess Email" onChange={handlechangeProfileData} name="email" className='colored-input orange-cl'/>
                                    </div>
                                </div>
                            </div>

                            <div className='select-parent d-flex justify-content-end no-after has-disclamer'>
                                <div className='cont-select d-flex flex-column'>
                                    <div className='relative-parent d-flex justify-content-end align-items-md-end flex-column flex-md-row'>
                                        <p className='disclaimer-text'>Optional</p>
                                        <input type="numner" placeholder="Phone Number" onChange={handlechangeProfileData} name="phoneNumber" className='colored-input orange-cl'/>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row> 

                    <Row>
                        <Col>
                            <div className='select-parent d-flex justify-content-between flex-column flex-md-row'>
                                <p className='green-text'>The region in which your organization is based</p>
                                <div className='cont-select d-flex flex-column'>
                                    <label htmlFor='companySize'>Country</label>
                                    <div className='relative-parent white-bg'>
                                        <select className='white-select blue-cl' value="Bahrain" onChange={handleChnageCountry}> 
                                            <option value="Bahrain">Bahrain</option>
                                            <option value="Egypt">Egypt</option>
                                            <option value="Kenya">Kenya</option>
                                            <option value="Kuwait">Kuwait</option>
                                            <option value="Mauritius">Mauritius</option>
                                            <option value="Nigeria">Nigeria</option>
                                            <option value="Oman">Oman</option>
                                            <option value="Pakistan">Pakistan</option>
                                            <option value="Qatar">Qatar</option>
                                            <option value="Saudi Arabia">Saudi Arabia</option>
                                            <option value="South Africa">South Africa</option>
                                            <option value="United Arab Emirates">United Arab Emirates</option>
                                            <option value="Other">Other (please specify)</option>
                                        </select>
                                        <div className='abosulte-icon blue-cl'>
                                            <FontAwesomeIcon icon={faArrowDown} />
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>

                            {
                                anotherCountry && 
                                <div className='select-parent d-flex justify-content-end no-after'>
                                    <div className='cont-select d-flex flex-column'>
                                        <div className='relative-parent'>
                                            <input type="text" placeholder="Country" onChange={handlechangeProfileData} name="country" className='colored-input blue-cl'/>
                                        </div>
                                    </div>
                                </div>
                            }

                            <div className='select-parent d-flex justify-content-end no-after'>
                                <div className='cont-select d-flex flex-column'>
                                    
                                    <div className='relative-parent'>
                                        <input type="text" placeholder="City" onChange={handlechangeProfileData} name="city" className='colored-input green-cl'/>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </section>
            </Container>
            <NextPrevois 
                next={ (Object.keys(profileInfo).length == 7 || (Object.keys(profileInfo).length == 6 && profileInfo?.country == 'Bahrain') )  ? `/result` : false} handleTotalState={handleTotalState} 
                prev={`/question-5`} 
            />  

            
        </>
    );
}

export default FinalPage;




