import React, { useContext } from "react";
import stepOneIcon from "../../assets/img/steps-icons/1.svg";
import stepTwoIcon from "../../assets/img/steps-icons/2.svg";
import stepThreeIcon from "../../assets/img/steps-icons/3.svg";
import stepFourIcon from "../../assets/img/steps-icons/4.svg";
import ScreenScore from "../../context/ScreensContext/ScreenScore";
import "./TopSteps.scss";
const Step = ({ stepIcon, title }) => {
  return (
    <>
      <div className="img-wrap d-flex align-items-center w-100 ">
        {/* {stepIcon == stepOneIcon ? "" : <div className="tail flex-grow-1"> </div>} */}
        <img src={stepIcon} alt="step" className={stepIcon == stepTwoIcon ? "ms-2" :  stepIcon == stepThreeIcon ? "mx-3" : ""}/>
        {/* {stepIcon == stepFourIcon ? "" : <div className="tail flex-grow-1"> </div>} */}
      </div>
      <h3 className="mt-3 position-absolute text-center ">{title}</h3>
    </>
  );
};

const TopSteps = () => {
  const [step] = useContext(ScreenScore)
  return (
    <div className="top-steps container py-5 mb-4 d-none d-md-block">
      {console.log("top steps",step)}
      <div className="d-flex align-items-center">
        <div className={` step position-relative pe-0 d-flex flex-column step-1  ${step.Step >= 2 ? "active" : "" }`}>
          <Step stepIcon={stepOneIcon} title="Profile" />
        </div>
        <div className="tail flex-grow-1"> </div>
        <div className={` step position-relative  px-0 d-flex flex-column align-items-center step-2 ${step.Step >= 3 ? "active" : "" }`}>
          <Step stepIcon={stepTwoIcon} title="Business Priorities" />
        </div>
        <div className="tail flex-grow-1 "> </div>
        <div className={`step position-relative px-0 d-flex flex-column align-items-center step-3 ${step.Step >= 4 ? "active" : "" }`}>
          <Step stepIcon={stepThreeIcon} title="Technology Spending" />
        </div>
        <div className="tail flex-grow-1 "> </div> 
        <div className=" step position-relative ps-0 d-flex flex-column align-items-center step-4">
          <Step stepIcon={stepFourIcon} title="Organizational Resilience" />
        </div>
      </div>
    </div>
  );
};

export default TopSteps;