import { BrowserRouter, Routes, Route  } from "react-router-dom";
import "./App.scss";
import Header from "./components/Header/index";
import Landing from "./components/Landing";
import QuestionOne from "./components/questions/QuestionOne/QuestionOne";
import StartPage from "./components/Start";
import TopSteps from "./components/TopSteps/TopSteps";
import QuestionTwo from "./components/questions/QuestionTwo/QuestionTwo";
import TechStepThree from "./components/Tech/TechStepThree";
import StepThreeTechTwo from "./components/questions/StepThreeTechTwo/StepThreeTechTwo";
import QuestionFour from "./components/questions/QuestionFour/QuestionFour";
import ScreenScore from "./context/ScreensContext/ScreenScore";
import OperationOrItContext from './context/OperationOrIt/OperationOrItContext';
import Operation1 from './components/Operation/Operation1/Operation1';
import QuestionFive from "./components/questions/QuestionFive/QuestionFive";
import FinalPage from "./components/FinalPage/FinalPage";
import GenerateReport from "./components/GenerateReport/GenerateReport";
import Login from "./components/Auth/Login/Login";
import Dashboard from "./components/Admin/Dashboard/Dashboard";
import {  useState , useEffect } from "react";


const  App = () => {
  const [fetchStorage,setFecthStorage] =useState(true);
  // global user score
  const [Score, SetScore] = useState({
    QuestionOne: 0,
    QuestionTwo: 0,
    QuestionThree: 0,
    QuestionThreeItOne:0,
    StepThreeTechTwo:0, 
    QuestionFour: 0,
    Step:0,
    QuestionOneData:{},
    QuestionTwoData:{},
    QuestionFourData:{},
    QuestionFiveData:{},
    StepThreeTechTwoData:{},
    Operation1Data:{},
    StartPagedata:{},
    progressOnPages:0,
    refreshFlag:0
  });
  
  useEffect(()=>{
    if (Score?.refreshFlag != 0 ) {
      // console.log('refreshed');
      // SetScore(JSON.parse(localStorage.getItem('scoreData')))
      // setFecthStorage(false)
      localStorage.setItem('scoreData', JSON.stringify(Score));
    }  
    
  },[Score, fetchStorage])

  useEffect(()=>{
    if (Score?.refreshFlag == 0 && localStorage.getItem('scoreData') ) {
      SetScore(JSON.parse(localStorage.getItem('scoreData')));
    }  
    
  },[])


  // user type
  const [userType,setUserType] = useState("IT")

  //declare pathname to remove steps component from laning page
  
  

  return (
    <div className="App">
      
      <ScreenScore.Provider value={[Score, SetScore]}>
        <OperationOrItContext.Provider  value={[userType,setUserType]}>
        <BrowserRouter>
          <Header />
          <TopSteps />
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="/start" element={<StartPage />} />
            <Route path="/question-1" element={<QuestionOne />} />
            <Route path="/question-2" element={<QuestionTwo />} />
            <Route path="/question-3-it" element={<TechStepThree />} />
            <Route path="/question-3-it-two" element={<StepThreeTechTwo />} />
            <Route path="/question-3-operation" element={<Operation1 />} />
            <Route path="/question-4" element={<QuestionFour />} />
            <Route path="/question-5" element={<QuestionFive/>} />  
            <Route path='/final-step' element={<FinalPage />} />
            <Route path='/result' element={<GenerateReport/>}/>
            <Route path='/login' element={<Login/>}/> 
            <Route path='/dashboard' element={<Dashboard/>}/>
          </Routes>


        </BrowserRouter>
        </OperationOrItContext.Provider>
      </ScreenScore.Provider>
    </div>
    
  );

}

export default App;
