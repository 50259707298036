import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./LeftImageBlock.scss";

const LeftImageBlock = ({ image, title1, text1, text2, title2, text3 }) => {
  return (
    /* usage
    
         <LeftImageBlock
        image={}
        title1={``}
        text1={``}
        text2={``}
        title2={``}
        text3={``}
      />
    
    */
    <Container className="left-image-block mt-5 pt-1">
      <Row className="align-items-center">
        <Col md={2} className="d-flex pe-5  pb-3">
          <img src={image} alt="section-img" className="w-100"></img>
        </Col>
        <Col md={10} className="ps-4">
          {title1?.length > 0 && (
            <h2 className="text-white fw-bold title-1 fs-3 mb-3">{title1}</h2>
          )}
          {text1?.length > 0 && (
            <p className="text-white text-1 mb-3">{text1}</p>
          )}
          {text2?.length > 0 && <p className="text-white text-2 ">{text2}</p>}
        </Col>
      </Row>
      <div className="content-related mt-5 pt-5">
        {title2?.length > 0 && (
          <h2 className="text-white fw-bold title-2 fs-4">{title2}</h2>
        )}
        {text3?.length > 0 && <p className="text-3 fs-5">{text3}</p>}
      </div>
    </Container>
  );
};

export default LeftImageBlock;
