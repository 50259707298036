import React, { useContext } from 'react';
import { Button, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ScreenScore from '../../../context/ScreensContext/ScreenScore';
import "./NextPrevois.scss";
const NextPrevois = ({next , prev, handleTotalState}) => {
    const [step] = useContext(ScreenScore)

    return (
        <Container className='next-previous d-flex justify-content-between my-5'>
            <Link to={prev} className='p-2  previous' onClick={handleTotalState}> 
                <Button className='fw-bold py-3 px-4 border-0 m-2'>Previous</Button>
            </Link>
            <Link to={next} className={`p-2  next ${step.Step >4 ? "last-step" : ""}`} onClick={handleTotalState}>
                <Button className='fw-bold py-3 px-4 border-0 m-2' disabled={!next}>{step.Step >4 ? "Submit" : "Next"}</Button>
            </Link>
        </Container>
    );
};

export default NextPrevois;