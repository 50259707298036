import React, { useContext, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import LeftImageBlock from "../../LeftImageBlock/LeftImageBlock";
import image from "../../../assets/img/organiationImage.svg";
import VerticalRadio from "../../Blocks/VerticalRadio/VerticalRadio";
import NextPrevois from "../../Blocks/NextPrevois/NextPrevois";
import ScreenScore from "../../../context/ScreensContext/ScreenScore";
import OperationOrItContext from "../../../context/OperationOrIt/OperationOrItContext";


const QuestionFive = () => { 
    const [score, SetScreenScore] = useContext(ScreenScore);
    const [StepInsideScore, setStepInsideScore] = useState({default:0});

    // check for step previous regarding the user type 
    const [userType] = useContext(OperationOrItContext)


    /* path this handle change as props ro set the step value */ 
    const handleQuestionScore = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        setStepInsideScore({ ...StepInsideScore, [name]: parseInt(value) });
        SetScreenScore({...score,QuestionFour:parseInt(value)});
    }; 

      // calculate totla score
        const handleTotalState = ()=> {
            let  sumValues =  Object.values(StepInsideScore).reduce((a, b) => a + b);
            SetScreenScore({...score,QuestionFour:sumValues,QuestionFourData:StepInsideScore,progressOnPages:5,refreshFlag:1});
            // localStorage.setItem('scoreData', JSON.stringify(score)); 
        }

//change top steps
useEffect(()=>{
    SetScreenScore({...score,Step:4})
},[])
    return (
            <Container>
                {console.log("st 4",score)}
            <LeftImageBlock
                image={image}
                title1={`Organizational Resilience`}
                text1={`These questions are related your organization's level of achievement across various organization priorities.`}
                text2={`Organizational priorities are outcomes that an organization considers important and sets out to achieve. It is essential for organizations to prioritize outcomes and measure the level of achievement for each.`}
                title2={`Which of the following best reflects your organization’s resiliency objectives for 2022 and beyond?`}
                text3={`Please select one answer.`}
            />
            
            <VerticalRadio
                name="stepFour"
                title="None"
                desc="We have no resiliency plans in place and no intention to invest in this area."
                value="0"
                handleQuestionScore={handleQuestionScore}
                defaultVal={score.QuestionFourData?.stepFour}
            />

            <VerticalRadio
                name="stepFour"
                title="Business continuity planning (BCP)"
                desc="We had business continuity plans in place prior to COVID-19 and no plans to expand beyond these."
                value="0"
                handleQuestionScore={handleQuestionScore}
                defaultVal={score.QuestionFourData?.stepFour}
            />

            <VerticalRadio
                name="stepFour"
                title="BCP+"
                desc="When COVID-19 hit, we expanded our resiliency plans to cover unexpected events, but we have no/little intention to go beyond this measure in 2022."
                value="1"
                handleQuestionScore={handleQuestionScore}
                defaultVal={score.QuestionFourData?.stepFour}
            />

            <VerticalRadio
                name="stepFour"
                title="Resiliency"
                desc="When COVID-19 hit, we expanded our resiliency plans to cover unexpected events and possible future business disruptions and to improve environment sustainable."
                value="4"
                handleQuestionScore={handleQuestionScore}
                defaultVal={score.QuestionFourData?.stepFour}
            />

            <VerticalRadio
                name="stepFour"
                title="Digital Resiliency"
                desc="We are preparing for possible future business disruptions and investing in digital capabilities to be able to adapt rapidly to any crisis and capitalize on the resultant changing conditions."
                value="7"
                handleQuestionScore={handleQuestionScore}
                defaultVal={score.QuestionFourData?.stepFour}
            />

            <VerticalRadio
                name="stepFour"
                title="Digital resiliency and environmental sustainability"
                desc="We are preparing for possible future business disruptions and building capabilities to create products and services that will improve the environment."
                value="10"
                handleQuestionScore={handleQuestionScore}
                defaultVal={score.QuestionFourData?.stepFour}
            />

            <NextPrevois 
                next={Object.keys(StepInsideScore).length == 2 || score.QuestionFourData.stepFour >= 0 ? `/question-5` : false}  
                prev={userType == "Operation" ? `/question-3-operation`: `/question-3-it-two`}
                handleTotalState={handleTotalState}
            /> 
            </Container>
    );
};

export default QuestionFive;
