import React, { useEffect  , useContext} from 'react';
import idcLogo from '../../assets/img/header-img/idc-logo.svg';
import sapLogo from '../../assets/img/header-img/sap-logo.svg';
import siemensLogo from '../../assets/img/header-img/siemnes-logo.svg';
import leftImage from '../../assets/img/header-img/left-header-img.svg';
import rightImage from '../../assets/img/header-img/right-header-img.svg';
import landingImage from '../../assets/img/landingImage.svg';
import { useLocation , useNavigate } from 'react-router-dom';
import OperationOrItContext from '../../context/OperationOrIt/OperationOrItContext';
const Header = () => {
  // back to top every render 
    const { pathname } = useLocation();
    const navigate = useNavigate();

//define user type to detect routing 
const [userType] = useContext(OperationOrItContext);

useEffect(() => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth', 
  });
}, [pathname]);

const handleRouteuser = (N) => {

    if ( pathname != '/dashboard' && pathname != '/login') { 
        if (N > 0 ) {
            if ( N === 1 ) {
                navigate('/question-1')
            } else if ( N === 2 ) { 
                navigate('/question-2')
            } else if ( N === 3 && userType === "Operation" ) { 
                navigate('/question-3-operation')
            } else if ( N === 3 && userType !== "Operation" ) { 
                navigate('/question-3-it')
            } else if ( N === 4 ) { 
                navigate('/question-3-it-two')
            } else if ( N === 5 ) { 
                navigate('/question-4')
            } else if ( N === 6 ) { 
                navigate('/question-5')
            } else if ( N === 7 ) { 
                navigate('/final-step')
            }  else if ( N === 8) { 
                navigate('/result')
            }
        } else {
            navigate('/')
        }
    }
    
}
const whereUserIs = JSON.parse(localStorage.getItem('scoreData')); 
//change top steps
useEffect(()=>{
  handleRouteuser(whereUserIs?.progressOnPages);
},[])

    return ( 
        <>
            <header className={`container ${pathname === "/" || pathname === "/final-step" || pathname === "/result" || pathname === "/login" || pathname === "/dashboard" ? 'landingHeaderParent' : ''}`}>
                <div className='top-header d-flex justify-content-between  py-4'>
                    <div className='cont-img'> 
                        <img src={idcLogo}  alt="idc logo"/>
                    </div>

                    <div className='cont-img d-flex align-items-center sap-siemens-parent'>
                        <img src={sapLogo}  alt="idc logo"/>
                        <img src={siemensLogo}  className="siemnsLogo" alt="idc logo"/>
                    </div>
                </div>

                <div className="heading-parent w-100 d-flex justify-content-center align-items-center flex-column">
                    {
                        pathname === "/" && 
                        <div className="cont-img mt-3 mb-5">
                            <img src={landingImage}  alt="idc logo"/>
                        </div>
                    } 
                    <div className="cont-text">
                        <h1>
                            <span className="bold-text">ASSESSMENT </span> OF<br/>
                            ORGANIZATIONAL RESILIENCY<br/>
                            <span className="bold-text white-text">MIDDLE EAST, 2022</span>
                        </h1>
                    </div>
                    {/* <div className="cont-img">
                        <img src={rightImage}  alt="idc logo"/>
                    </div> */}
                </div>
            </header>
        
        </>
    );
}

export default Header;