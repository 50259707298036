import React from "react";
import "./MatchedRadio.scss";
const MatchedRadio = ({name,title,handleQuestionScore,values,savedValue}) => {
  return (
    <div className="matched-radio-wrap mt-4">
      <h3>
          {title}
      </h3>
        <div className="matched-radio d-flex justify-content-between align-items-center py-4"> 
          <label>
            <input type="radio" name={name} value={values[0]} onChange={handleQuestionScore}  defaultChecked={savedValue == values[0] ? true :false}/>
            <span className="fw-bold rounded-circle bg-white text-black d-flex align-items-center justify-content-center">
              1
            </span>
          </label>
          <hr className="bg-white border-1 border flex-grow-1"></hr>
          <label>
            <input type="radio" name={name} value={values[1]} onChange={handleQuestionScore} defaultChecked={savedValue == values[1] ? true :false}/>
            <span className="fw-bold rounded-circle bg-white text-black d-flex align-items-center justify-content-center">
              2
            </span>
          </label>
          <hr className="bg-white border-1 border flex-grow-1"></hr>

          <label>
            <input type="radio" name={name} value={values[2]} onChange={handleQuestionScore} defaultChecked={savedValue == values[2] ? true :false}/>
            <span className="fw-bold rounded-circle bg-white text-black d-flex align-items-center justify-content-center">
              3
            </span>
          </label>
          <hr className="bg-white border-1 border flex-grow-1"></hr>

          <label>
            <input type="radio" name={name} value={values[3]} onChange={handleQuestionScore} defaultChecked={savedValue == values[3] ? true :false}/>
            <span className="fw-bold rounded-circle bg-white text-black d-flex align-items-center justify-content-center">
              4
            </span>
          </label>
          <hr className="bg-white border-1 border flex-grow-1"></hr>

          <label>
            <input type="radio" name={name} value={values[4]} onChange={handleQuestionScore} defaultChecked={savedValue == values[4] ? true :false}/>
            <span className="fw-bold rounded-circle bg-white text-black d-flex align-items-center justify-content-center">
              5
            </span>
          </label>
        </div>
    </div>
  );
};

export default MatchedRadio;
