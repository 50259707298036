import React, { useContext, useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import LeftImageBlock from "../../LeftImageBlock/LeftImageBlock";
import image from "../../../assets/img/TechnologySpending.svg";
import NextPrevois from "../../Blocks/NextPrevois/NextPrevois";
import "./StepThreeTechTwo.scss";
import ScreenScore from "../../../context/ScreensContext/ScreenScore";

const StepThreeTechTwo = () => {
  const [score, SetScreenScore] = useContext(ScreenScore);
  const [StepInsideScore, setStepInsideScore] = useState({default:0});
//change top steps
useEffect(()=>{
  SetScreenScore({...score,Step:3})
},[])
  /*
      path this handle change as props ro set the step value 
      
      */
  //   modernization
  //   transformation
  //   initiatives
  //   sustainability
  const handleQuestionScore = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (value == 0) {
      setStepInsideScore({ ...StepInsideScore, [name]: parseInt(value) });
    } else if (value >= 1 && value <= 4) {
      if (name == "modernization" || name == "initiatives") {
        setStepInsideScore({ ...StepInsideScore, [name]: 2 });
      } else if (name == "transformation") {
        setStepInsideScore({ ...StepInsideScore, [name]: 1 });
      } else {
        setStepInsideScore({ ...StepInsideScore, [name]: 3 });
      }
    } else if (value >= 5 && value <= 9) {
      if (name == "modernization" || name == "initiatives") {
        setStepInsideScore({ ...StepInsideScore, [name]: 3 });
      } else if (name == "transformation") {
        setStepInsideScore({ ...StepInsideScore, [name]: 2 });
      } else {
        setStepInsideScore({ ...StepInsideScore, [name]: 5 });
      }
    } else if (value >= 10 && value <= 14) {
      if (name == "modernization" || name == "initiatives") {
        setStepInsideScore({ ...StepInsideScore, [name]: 7 });
      } else if (name == "transformation") {
        setStepInsideScore({ ...StepInsideScore, [name]: 3 });
      } else {
        setStepInsideScore({ ...StepInsideScore, [name]: 7 });
      }
    } else if (value >= 15) {
      if (name == "modernization" || name == "initiatives") {
        setStepInsideScore({ ...StepInsideScore, [name]: 10 });
      } else if (name == "transformation") {
        setStepInsideScore({ ...StepInsideScore, [name]: 5 });
      } else {
        setStepInsideScore({ ...StepInsideScore, [name]: 10 });
      }
    }
  };

  // calculate totla score
  const handleTotalState = () => {
    let sumValues = Object.values(StepInsideScore).reduce((a, b) => a + b);
    SetScreenScore({ ...score, QuestionThreeItTwo: sumValues ,progressOnPages:4,refreshFlag:1});
    // localStorage.setItem('scoreData', JSON.stringify(score)); 
  };

  return (
    <Container>
      {console.log("st 3 1 ", score)}
      <LeftImageBlock
        image={image}
        title1={`Technology Spending`}
        text1={`These questions are related your organization's level of achievement across various organization priorities.`}
        text2={`Organizational priorities are outcomes that an organization considers important and sets out to achieve. It is essential for organizations to prioritize outcomes and measure the level of achievement for each.`}
        title2={`Based on your best estimate, what was the split of your organization's overall spending on technology in 2021 among the following categories?`}
        text3={`Please enter the percentage. If none, please enter 0%.`}
      />
      <div className="answers-parent">
        <Row className="mb-5 step-three-tech">
          <Col md={6} xs={8}>
            <span className="bold-text question-text">
              Sustaining ongoing modernization
            </span>
          </Col>
          <Col md={6} xs={4}>
            <div className="d-flex justify-content-end">
              <div className="relative-parent">
                <input
                  className="white-input"
                  type="number"
                  name="modernization"
                  onChange={handleQuestionScore}
                />
                <span className="yellow-text bold-text absolute-percentage">
                  %
                </span>
              </div>
            </div>
          </Col>
        </Row>

        <Row className="mb-5 step-three-tech">
          <Col md={6} xs={8}>
            <span className="bold-text question-text">
              Enterprise-wide transformation
            </span>
          </Col>

          <Col md={6} xs={4}>
            <div className="d-flex justify-content-end">
              <div className="relative-parent">
                <input
                  className="white-input"
                  type="number"
                  name="transformation"
                  onChange={handleQuestionScore}
                />
                <span className="yellow-text bold-text absolute-percentage">
                  %
                </span>
              </div>
            </div>
          </Col>
        </Row>

        <Row className="mb-5 step-three-tech">
          <Col md={6} xs={8}>
            <span className="bold-text question-text">
              New innovation-related initiatives
            </span>
          </Col>
          <Col md={6} xs={4}>
            <div className="d-flex justify-content-end">
              <div className="relative-parent">
                <input
                  className="white-input"
                  type="number"
                  name="initiatives"
                  onChange={handleQuestionScore}
                />
                <span className="yellow-text bold-text absolute-percentage">
                  %
                </span>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="step-three-tech">
          <Col md={6} xs={8}>
            <span className="bold-text question-text">
              New environmental sustainability & innovation-related initiatives
            </span>
          </Col>
          <Col md={6} xs={4}>
            <div className="d-flex justify-content-end">
              <div className="relative-parent">
                <input
                  className="white-input"
                  type="number"
                  name="sustainability"
                  onChange={handleQuestionScore}
                />
                <span className="yellow-text bold-text absolute-percentage">
                  %
                </span>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <NextPrevois
        next={Object.keys(StepInsideScore).length == 5 ? `/question-4` : false} 
        prev={`/question-3-it`}
        handleTotalState={handleTotalState}
      />
    </Container>
  );
};

export default StepThreeTechTwo;
