import React from "react";
import "./VerticalRadio.scss";
const VerticalRadio = ({name,title,desc, value,handleQuestionScore, defaultVal}) => { 
    return (
        <div className="vertical-radio-wrap d-flex align-items-center mt-4">      
            <div className="cont-input">
                <input type="radio" name={name}  value={value} onChange={handleQuestionScore} defaultChecked={value == defaultVal ? true :false}/>
            </div>
            <div className="cont-text">
                <h3 className="fs-3">{title}</h3> 
                <p className="yellow-text">{desc}</p>
            </div>
        </div>
    );
};

export default VerticalRadio;
