import React, { useContext, useEffect, useState } from "react";
import {  Container, Row } from "react-bootstrap";
import LeftImageBlock from "../../LeftImageBlock/LeftImageBlock";
import stepIconIt from "../../../assets/img/question5Tech.svg";
import stepIconOpertaion from "../../../assets/img/questionFiveOperation.svg";
import MatchedRadio from "../../Blocks/MatchedRadio/MatchedRadio";
import NextPrevois from "../../Blocks/NextPrevois/NextPrevois";
import ScreenScore from "../../../context/ScreensContext/ScreenScore";
import OperationOrItContext from "../../../context/OperationOrIt/OperationOrItContext";


const QuestionFive = () => {
    const [score,SetScreenScore] = useContext(ScreenScore);
    const [StepInsideScore, setStepInsideScore] = useState({default:0});


    const [userType] = useContext(OperationOrItContext)
//change top steps
useEffect(()=>{
    SetScreenScore({...score,Step:4})
},[])
    /*
    path this handle change as props ro set the step value 
    
    */ 
    const handleQuestionScore = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        setStepInsideScore({ ...StepInsideScore, [name]: parseInt(value) });
    };


  // calculate totla score
const handleTotalState = ()=> {
    let  sumValues =  Object.values(StepInsideScore).reduce((a, b) => a + b);
    SetScreenScore({...score,QuestionFive:sumValues,QuestionFiveData:StepInsideScore,progressOnPages:6,refreshFlag:1});
    // localStorage.setItem('scoreData', JSON.stringify(score)); 
}



  return (
    <Container>
        
        <Row>

            {
                userType === "IT" ? 
                <LeftImageBlock
                    image={stepIconIt}
                    title1={`Organizational Resilience`}
                    text1={`These questions are related your organization's level of achievement across various organization priorities.`}
                    text2={`Organizational priorities are outcomes that an organization considers important and sets out to achieve. It is essential for organizations to prioritize outcomes and measure the level of achievement for each.`}
                    title2={`In which of these areas do you expect your organization will invest to help build, retain, and strengthen your organization's resiliency across the organization in the next 1-2 years?`}
                    text3={`Please rate each using a 5-scale where 1 means "Will definitely not invest in" and 5 means "Will definitely invest in".`}
                /> : 
                <LeftImageBlock 
                    image={stepIconOpertaion}
                    title1={`Operational priorities`}
                    text1={`These questions are related your organization's level of achievement across various organization priorities.`}
                    text2={`Organizational priorities are outcomes that an organization considers important and sets out to achieve. It is essential for organizations to prioritize outcomes and measure the level of achievement for each.`}
                    title2={`In which of these areas do you expect your organization will invest to help build, retain, and strengthen your organization's resiliency across the organization in the next 1-2 years?`}
                    text3={`Please rate each using a 5-scale where 1 means "Will definitely not invest in" and 5 means "Will definitely invest in".`}
                />
            }

            <MatchedRadio
                title={userType === "Operation" ? `Initiatives that trigger cultural changes and strengthen resiliency`: `Next-gen technologies to enable sustained innovation`} 
                name={`choice1`}
                handleQuestionScore={handleQuestionScore}
                values={[0,2,5,8,10]}
                savedValue={score.QuestionFiveData?.choice1}
            />
            <MatchedRadio
                title={userType === "Operation" ? `Processes that help build environmentally sustainable products and services`: `Modernizing legacy enterprise systems and in technologies that help improve CX`} 
                name={`choice2`}
                handleQuestionScore={handleQuestionScore} 
                values={[0,2,5,8,10]}
                savedValue={score.QuestionFiveData?.choice2}
            />
            <MatchedRadio
                title={userType === "Operation" ? `Revitalizing core systems and in business process reengineering initiatives`: `Modernizing IT Infrastructure and adopting cloud to strengthen resiliency`} 
                name={`choice3`}
                handleQuestionScore={handleQuestionScore}
                values={[0,2,5,8,10]}
                savedValue={score.QuestionFiveData?.choice3}
            />
            

            
            
            
        </Row>
        <NextPrevois
            next={Object.keys(StepInsideScore).length == 4 || Object.keys(score.QuestionFiveData).length ==  4  ? `/final-step` : false} 
            prev={`/question-4`}  handleTotalState={handleTotalState}/>
    </Container>
    );
};

export default QuestionFive;
