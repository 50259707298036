import React, { useContext, useEffect, useState } from "react";
import {  Container, Row } from "react-bootstrap";
import LeftImageBlock from "../../LeftImageBlock/LeftImageBlock";
import "./QuestionOne.scss";
import stepIcon from "../../../assets/img/steps-icons/2.svg";
import MatchedRadio from "../../Blocks/MatchedRadio/MatchedRadio";
import NextPrevois from "../../Blocks/NextPrevois/NextPrevois";
import ScreenScore from "../../../context/ScreensContext/ScreenScore";

const QuestionOne = () => {
  const [score,SetScreenScore] = useContext(ScreenScore);
  const [StepInsideScore, setStepInsideScore] = useState({default:0});
  
  const pageAnswersData = JSON.parse(localStorage.getItem('scoreData'));
  /*
  path this handle change as props ro set the step value
  */ 
  const handleQuestionScore = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setStepInsideScore({ ...StepInsideScore, [name]: parseInt(value) });
  };


  // calculate totla score
const handleTotalState = ()=> {
  let  sumValues =  Object.values(StepInsideScore).reduce((a, b) => a + b);
  SetScreenScore({...score,QuestionOne:sumValues,QuestionOneData:StepInsideScore,progressOnPages:1,refreshFlag:1}); 
    // localStorage.setItem('scoreData', JSON.stringify(score)); 

}
//change top steps
useEffect(()=>{
  SetScreenScore({...score,Step:2})
},[])


  return (
    <Container>
      {console.log('i am in question one page ', score?.QuestionOneData)}
      <Row>
        <LeftImageBlock
          image={stepIcon}
          title1={`Business Priorities`}
          text1={`These questions are related your organization's level of achievement across various organization priorities.`}
          text2={`Organizational priorities are outcomes that an organization considers important and sets out to achieve. It is essential for organizations to prioritize outcomes and measure the level of achievement for each.`}
          title2={`For each of the following business priorities, how would you rate the level of achievement your organization has achieved in the past 1-2 years?`}
          text3={`Please rate each using a 5-scale where 1 means "very low achievement" and 5 means "very high achievement".`}
        />

        <MatchedRadio
          title={`Achieving revenue growth:`}
          name="revenue"
          handleQuestionScore={handleQuestionScore}
          values={[0,1,2,3,4]}
          savedValue={score?.QuestionOneData.revenue} 
        />
        <MatchedRadio
          title={`Achieving profit growth:`}
          name="profit"
          handleQuestionScore={handleQuestionScore}
          values={[0,1,2,3,3]} 
          savedValue={score?.QuestionOneData.profit}
        />
        <MatchedRadio
          title={`Ensuring corporate sustainability and ESG:`}
          name="ESG"
          handleQuestionScore={handleQuestionScore}
          values={[0,2,3,4,5]}
          savedValue={score?.QuestionOneData.ESG}
        />
        <MatchedRadio
          title={`Creating a culture of innovation:`}
          name="innovation"
          handleQuestionScore={handleQuestionScore}
          values={[0,2,3,3,5]}
          savedValue={score?.QuestionOneData.innovation}
        />
        <MatchedRadio
          title={`Strengthening business resiliency:`}
          name="resiliency"
          handleQuestionScore={handleQuestionScore}
          values={[0,1,3,4,5]}
          savedValue={score?.QuestionOneData.resiliency}
        />
        <MatchedRadio
          title={`Improving customer experience, satisfaction, and retention:`}
          name="retention"
          handleQuestionScore={handleQuestionScore}
          values={[0,1,2,3,4]}
          savedValue={score?.QuestionOneData.retention} 
        />
        <MatchedRadio
          title={`Increasing revenue from new markets, products, or customers:`}
          name="Increasing"
          handleQuestionScore={handleQuestionScore}
          values={[0,1,2,3,4]}
          savedValue={score?.QuestionOneData.Increasing}
        />       
        <MatchedRadio
          title={`Reducing business risk:`}
          name="Reducing"
          handleQuestionScore={handleQuestionScore}
          values={[0,1,3,3,5]}
          savedValue={score?.QuestionOneData.Reducing}
        />
      </Row> 
      <NextPrevois next={ Object.keys(score?.QuestionOneData).length == 9 || Object.keys(StepInsideScore).length == 9 || Object.keys(score.QuestionOneData).length ==  9 ? `/question-2` : false} prev={`/start`}  handleTotalState={handleTotalState}/>
    </Container>
  );
};

export default QuestionOne;
